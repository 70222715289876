#languages-container{
    margin-top: 3%;
    display:flex;
    flex-wrap: wrap;
}

.lang-box{
    background-color: var(--fg);
    padding: 1%; 
    margin: 0 0.5%;
    border-radius: calc(0.5em + 1%);
    display: flex;
    flex: 0 0 30%;
    flex-direction: column;
    align-items: center;
    margin-bottom: calc(0.2em + 1vmin);
    align-self: flex-start;
    overflow-y: hidden;

    transition: box-shadow 0.2s ease-out;
}
.lang-box img{
    max-width: 25%;
    height: fit-content;
    object-fit: contain;
    margin-left: calc(0.1em + 1%);
    margin-right:calc(0.2em + 1.5vmin);
}
.lang-box .lang-progress{
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.lang-box-thumbail{
    align-self: flex-start;
    background-color: var(--fg);
    display: flex;
    width: 100%;
}

.lang-box p {
    margin-bottom: 10%;
}
.progress-bar-bg{
    background-color: var(--progress-bg);
    height:calc(5px + 1vmin);
    border-radius:5em;
}

.progress-bar{
    background-color: var(--progress-green);
    border-radius:5em;
    height:100%;
}

.lang-box:hover{
    box-shadow: inset 0 0 0px 2px var(--detail);
}


.lang-extra{
    max-height: 0;
    transition: max-height 0.5s ease;
    width: 100%;
}

.lang-extra p{
    margin-top: 2%;
    margin-bottom: 2%;
    font-size: calc(0.4em + 0.6vw);
}

.lang-box:hover .lang-extra{
    max-height: 100px;
    opacity: 1;
}
@media screen and (max-width: 420px) {
    .lang-box {
        flex: 0 0 46%;
    }
}

.section-text-area.hidden .progress-bar{
    transition: width 0s ease-out
}
.section-text-area.show .progress-bar{
    transition: width 0.8s ease-out;
    transition-delay: 0.4s;
}
/* .section-text-area.show .progress-bar#cs-progress{
    width:var(--cs-progress);
}
.section-text-area.show .progress-bar#js-progress{
    width:var(--js-progress);
}
.section-text-area.show .progress-bar#java-progress{
    width:var(--java-progress);
}
.section-text-area.show .progress-bar#ruby-progress{
    width:var(--ruby-progress);
}
.section-text-area.show .progress-bar#cpp-progress{
    width:var(--cpp-progress);
}
.section-text-area.show .progress-bar#python-progress{
    width:var(--python-progress);
} */