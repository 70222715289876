.video-embed-container{
    margin-top: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-embed{
    position: relative;
    width: 60vmax;
    height: 30vmax;
    border-radius: calc(0.4em + 2vmin);
    border:solid 0.4vmin var(--detail);
}
@media screen and (max-width: 760px) {
    .video-embed{
        width: 80vmin;
        height: 45vmin;
    }
}

#springman-screenshots{
    width:90%;
    margin:auto;
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
}
#springman-screenshots > img{
    max-width: 18%;
    max-height: 100%;
    object-fit: contain;
    margin: 0 1%;
}

@media (min-width:793px){
    #springman-screenshots.hidden > img{
        transform: translateX(-100vw);
    }
    #springman-screenshots.show > img{
        transform: translateX(0);
        transform: translateX(-100vw);
        animation: slideIn 0.5s ease-out forwards;
        animation-delay: 0.2s;
    }
    #springman-screenshots.show :nth-child(4){
        animation-delay: 0.2s;
        transform: translateX(-100vw);
        animation-duration: 0.6s;
    }
    #springman-screenshots.show :nth-child(3){
        animation-delay: 0.2s;
        transform: translateX(-100vw);
        animation-duration: 0.7s;
    }
    #springman-screenshots.show :nth-child(2){
        animation-delay: 0.2s;
        transform: translateX(-100vw);
        animation-duration: 0.8s;
    }
    #springman-screenshots.show :nth-child(1){
        animation-delay: 0.2s;
        transform: translateX(-100vw);
        animation-duration: 0.9s;
    }
    @keyframes slideIn {
        0% {
            transform: translateX(-100vw);
        }
        100% {
            transform: translateX(0);
        }
    }
}
#springman-links{
    width:100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 2%;
}
#springman-googleplay{

    width: 30vmin;
    object-fit: contain;
}
#game-jams-container {
    margin-top: 3%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow-y: hidden;
}
.game-jam-box{
    background-color: var(--fg);
    padding: 1%;
    margin: 0 0.5%;
    border-radius: calc(0.5em + 1%);
    display: flex;
    flex: 0 0 30%;
    flex-direction: column;
    align-items: center;
    margin-bottom: calc(0.2em + 1vmin);
    align-self: flex-start;
    overflow-y: hidden;

    transition: box-shadow 0.2s ease-out;
}

.game-jam-box a{
    display: flex;
    flex-direction: column;
}

.game-jam-box:hover{
    box-shadow: inset 0 0 0px 2px var(--detail);
}

.game-jam-box-thumbnail{
    align-self: flex-start;
    background-color: var(--fg);
    display: flex;
    width: 100%;
    position: relative;
}
.game-jam-box-thumbnail p {
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.9);
    position: absolute;
    padding-left:2%;
}
.game-jam-box:hover .jam-details{
    max-height: 250px;
}

.game-jam-box-thumbnail > img{
    width:100%;
    border-radius: 0.5em;
}

.jam-details{
    max-height: 0;
    transition: max-height 0.5s ease;
    width: 100%;
}

.jam-details p{
    margin-top: 2%;
    margin-bottom: 2%;
    font-size: calc(0.4em + 0.6vw);
}

#game-jams-container.hidden .game-jam-box{
    transform: translateY(-110%);
    opacity: 0;
}
#game-jams-container.show .game-jam-box{
    transform: translateY(0);
    opacity: 1;
    transition: transform 0.5s ease, opacity 0.5s ease;
}
#game-jams-container.show :nth-child(2){
    transition-duration: 0.6s, 0.6s;
}
#game-jams-container.show :nth-child(3){
    transition-duration: 0.7s, 0.7s;
}

@media (max-width: 792px){
    #springman-screenshots{
        overflow-x: scroll;
    }
    #springman-screenshots > img{
        max-width: 40%;
    }
}

@media (max-width: 960px){
    .game-jam-box a{
        pointer-events: none;
    }
}

@media (max-width: 600px){
    .game-jam-box{
        flex: 0 0 46%;
    }
    #game-jams-container{
        justify-content: center;
    }
}

.mentoring-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
  }
  
.mentoring-item {
    width: 50%;
    box-sizing: border-box;
    padding: 1vmin;
}
.mentoring-item.side-text{
    width: 40%;
    box-sizing: border-box;
    padding-left: 0;
    flex-direction: column;
}

.mentoring-item.image-holder{
    width: 60%;
    display: flex;
    justify-content: center;
}

.mentoring-item.image-holder img{
    max-width: 100%;
    border-radius: calc(0.2em + 1vmin);
    border: solid 0.4vmin var(--detail);
}

@media screen and (max-width: 600px) {
    .mentoring-item.side-text {
        display: flex;
        width: 100%;
        justify-content: left;
    }
    .mentoring-item.image-holder{
        width: 100%;
    }
}