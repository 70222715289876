header{
    position: relative;
    background-color: var(--bg);
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
    z-index: 0;
}
.header-button{
    display: inline-flex;
    align-items: center;
    margin-left:2%;
}
.header-button-content{
    display: flex;
    align-items: center;
}

.header-button-text{
    font-size: calc(0.6em + 1.2vw);
    transition: color 0.2s ease-out;
    line-height: 1;
    margin-bottom: 3%;
}
#contact-button{
    text-decoration: underline;
}
.header-button-image{
    height: calc(0.6em + 1.2vw);
    fill:var(--text);
    transition: fill 0.2s ease-out;
    margin-right: 4px;
}

.header-button:hover .header-button-text{
    color: var(--detail);
}
.header-button:hover .header-button-image{
    fill: var(--detail);
}