.section-content{
    position: relative;
}
div.section-text-area{
    overflow-y: hidden;
}
div.section-text-area > div{
    padding:0 10px;
}
div.section-end-padding{
    width: 100%;
    height: calc(0.1em + 3vmin);
}
div.section-split{
    width: 100%;
    height: calc(0.7em + 25vmin);
}

div.subsection-split{
    width: 100%;
    height: calc(0.3em + 12vmin);
}

.section-content::before{
    content: '';
    position: absolute;
    top: calc(100%);
    left: 47.5%;
    width: 5%;
    height: 0px;
    border-bottom: solid 1vmin var(--detail);
    border-radius: 1vmin;
    z-index: -1;
}

.section-content.hidden::before{
    top: calc(-0.1em + -1.5vw);
}

.section-content.show::before{
    animation: section-end-pip-open 0.4s ease-out 1 forwards;
}

div.section-text-area.hidden > div{
    opacity: 0;
}
div.section-text-area.show > div{
    animation: section-open 0.4s ease-out 1 forwards;
}

@keyframes section-open{
    0%{
        opacity: 0;
        transform: translateY(-200%);
    }
    100%{
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes section-end-pip-open{
    0%{
        top: calc(-0.1em + -1.5vw);
    }
    100%{
        top: calc(100%);
    }
}

#game-dev-section .section-content.show::before{
    animation: section-end-pip-open 1.2s ease-out 1 forwards;
}

#game-dev-section div.section-text-area.show > div{
    animation: section-open 1.2s ease-out 1 forwards;
}

/* TUTORING / YOUTUBE */
.button-link-container{
    display: flex;
    justify-content: center;
    margin-top: calc(0.1em + 2%);
}
.button-link-container a{
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 3px var(--detail);
    border-radius: calc(0.5em + 0.5vmin);
    padding-top: calc(0.1em + 0.1vmin);
    padding-bottom: calc(0.2em + 0.2vmin);
    transition: background-color 0.3s ease;
}

.button-link-container a:hover{
    background-color: var(--detail);
    transition: background-color 0.3s ease;
}

.button-link-container a p{
    margin: 0;
    margin-right: 2%;
    font-size:calc(0.6em + 1.2vw);
    margin-bottom: calc(0.05em + 0.05vw);
}
.button-link-container a img{
    max-width: 10%;
}

/* CONTACT */
.contact-flex{
    display: flex;
    justify-content: center;
}
.contact-flex div{
    display: flex;
    flex-direction: column;
    margin: 0 1%;
}
.contact-flex p{
    font-size: calc(0.8em + 1vw);
}