div.flex-centre{
    display: flex;
    width:100%;
    justify-content: center;
}

div.flex-centre > div{
    background-color: var(--bg);
    width:90%;
    max-width: unset;
    margin:auto;
    z-index: 0;
}
@media (max-width: 1000px){
    div.flex-centre > div{
        width:980px;
        max-width: 980px;
    }
}

/* ABOUT SECTION */




/* LANGUAGES SECTION */



/* GAME DEVELOPMENT */

