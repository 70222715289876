.stacked-slider {
    position: relative;
    width: 80%;
    padding-bottom: 62.5%;
    margin-top: 2vw;
    margin-bottom: 2vw 
  }
  
  .slider-image-wrapper {
    width: 100%;
    padding-bottom: 62.5%; /* 16:10 aspect ratio */
    position: absolute;
    top: 0;
    left: calc((100% + 20px) * var(--index));
  }
  
  .aspect-ratio-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .slider-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    opacity: 0.6;
    z-index: 1;
    border-radius: calc(0.2em + 1vmin);
    border: solid 0.4vmin var(--detail);
  }
  
  .slider-image.active {
    opacity: 1;
    z-index: 2;
    transform: translate(-0px, -0px) scale(1.05); /* Adjust the scale and translation as needed */
    border: solid 0.6vmin var(--detail);
  }