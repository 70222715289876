.container {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }
  
  .item {
    width: 33%;
    box-sizing: border-box;
    padding: 1vmin;
  }
  .item.side-text{
    width: 33%;
    box-sizing: border-box;
    padding-left: 0;
    flex-direction: column;
  }

  .item.slider-holder{
    width:67%;
    display: flex;
    justify-content: center;
    padding-right: 2%;
  }
  
  @media screen and (max-width: 900px) {
    .item.side-text {
        display: flex;
        width: 100%;
        justify-content: left;
    }
    .item.slider-holder{
        width: 100%;
    }
  }