.results-div{
    margin-top: 3%;
    margin-bottom: 3%;
    padding: 0 3%;
    display: flex;
}
.results-div > ul{
    flex:1;
}

/* RESULTS DIV BREAKPOINT */
@media (max-width: 900px){
    .results-div{
        flex-direction: column;
        padding: 0 calc(0.5em + 10vmin);
    }
}

.list-dot{
    color: var(--detail);
    font-family: monospace;
    font-size: calc(1em + 2vmin);
}

ul li p > span.subject{
    margin-left: 10px;
    font-size: calc(0.7em + 1.3vmin);
}

ul li p > span.grade{
    margin-left:auto;
    font-size: calc(0.7em + 1.3vmin);
}