#headshot-pic-container{
    position: relative;
    display: flex;
    float: left;
    max-width: 20vmin;
    margin-right: 2%;
}

#headshot-pic{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius:99em;
    line-height: normal;
    vertical-align: middle;
    z-index: -1;

    box-shadow: 10vmin -10vmin 0 0.5vmin var(--bg), -10vmin -10vmin 0 0.5vmin var(--bg), -10vmin 10vmin 0 0.5vmin var(--bg), 10vmin 10vmin 0 0.5vmin var(--bg), 0 0 0 0.5vmin var(--bg);
    animation: border 0.5s linear 1 forwards;
}

h1{
    position: relative;
    transform: translateX(0%);
    animation: h1-intro 0.4s ease-out 1 forwards;
}
h1::before{
    content: "";
    position: absolute;
    bottom: calc(-1vmin - 2px);
    left:50%;
    width: 70vw;
    transform: translateX(-50%) translateX(15vw);
    border-bottom: 0.5vmin solid var(--detail);
    border-radius: 1vmin;

    animation: h1-line 0.6s ease-in 1 forwards;
}

@keyframes h1-intro{
    0%{
        transform: translateX(30%);
        opacity: 0;
    }
    100%{
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes h1-line{
    0%{
        width:0vw;
        transform: translateX(-50%) translateX(50vw);
    }
    100%{
        width:70vw;
        transform: translateX(-50%) translateX(15vw);
    }
}

@keyframes border{
    0% {
        box-shadow: 10vmin -10vmin 0 0.5vmin var(--bg), -10vmin -10vmin 0 0.5vmin var(--bg), -10vmin 10vmin 0 0.5vmin var(--bg), 10vmin 10vmin 0 0.5vmin var(--bg), 0 0 0 0.5vmin var(--bg);
        }
    50% {
        box-shadow: 21vmin 0vmin 0 0.5vmin var(--bg), -21vmin 0vmin 0 0.5vmin var(--bg), -10vmin 10vmin 0 0.5vmin var(--bg), 10vmin 10vmin 0 0.5vmin var(--bg), 0 0 0 0.5vmin var(--detail);
        }
    100% {
        box-shadow: 21vmin 0vmin 0 0.5vmin var(--bg), -21vmin 0vmin 0 0.5vmin var(--bg), 0vmin 21vmin 0 0.5vmin var(--bg), 0vmin 21vmin 0 0.5vmin var(--bg), 0 0 0 0.5vmin var(--detail);
        } 
}

@media (max-width: 385px) {
    #headshot-pic-container {
        float: none;
        margin-right: 0;

    }

    #hr-holder-pic .left-line, #hr-holder-pic .right-line{
        visibility:visible;
    }

    #hr-holder-pic{
        display: flex;
        justify-content: center;
    }

    h1{
        text-align: center;
        font-size: calc(1.5em + 4vw);
    }

    h1::before{
        border-width: 0.8vmin;
    }
}