@font-face {
    font-family: 'MontExtraLight';
    src: url('./fonts/Mont-ExtraLight.ttf') format('truetype');
}
@font-face {
    font-family: 'MontHeavy';
    src: url('./fonts/Mont-Heavy.ttf') format('truetype');
}
@font-face {
    font-family: 'MontLight';
    src: url('./fonts/Mont-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'MontSemiBold';
    src: url('./fonts/Mont-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'AmsiBold';
    src: url('./fonts/AmsiPro-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'AmsiLight';
    src: url('./fonts/AmsiPro-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'AmsiUltra';
    src: url('./fonts/AmsiPro-Ultra.ttf') format('truetype');
}
@font-face {
    font-family: 'AmsiExtraLight';
    src: url('./fonts/AmsiPro-XLight.ttf') format('truetype');
}


:root{
    --heavy: 'MontHeavy', "Arial", sans-serif;
    --semibold: 'MontSemiBold', "Arial", sans-serif;
    --light: 'MontLight', "Arial", sans-serif;
    --extra-light: 'MontExtraLight', Arial, sans-serif;

    --heavy: 'AmsiUltra', "Arial", sans-serif;
    --semibold: 'AmsiBold', "Arial", sans-serif;
    --light: 'AmsiLight', "Arial", sans-serif;
    --extra-light: 'AmsiExtraLight', Arial, sans-serif;

    --bg: #262626;
    --fg: #353535;
    --fg2:#424242;
    --text: #f8f8f8;
    --detail:#99e880;
    --progress-bg:#454545;
    --progress-green:#5bff84;
}

html{
    scroll-behavior: smooth;
}

h1, h2, h3, p{
    color: var(--text);
}

body{
    background-color: var(--bg);
    margin: 10px 0px;
    padding: 0px 2vw;
    overflow-x: hidden;
}


h1 {
    font-size: calc(2em + 5vw);
    font-family: var(--heavy);
    margin-bottom: calc(10% + 1vmin);
}

h2{
    font-size: calc(1em + 2vw);
    font-family: var(--semibold);
    margin-bottom: calc(0.1em + 1.5vw);
    color: var(--detail);

    text-align: center;
}

h3{
    font-size: calc(0.8em + 1.7vw);
    font-family: var(--semibold);
    margin-bottom: calc(0.1em + 1.5vw);
    color: var(--detail);

    text-align: center;
}

p{
    font-size: calc(0.5em + 1vw);
    font-family: var(--light);
    margin-bottom: calc(0.05em + 1vw);
    line-height: 1.3;
}

b{
    font-family: var(--semibold);
}

p.aside-text{
    font-size: calc(0.4em + 0.6vw);
    font-family: var(--extra-light);
}

p a{
    color: var(--detail);
    text-decoration: underline;
}

p span.highlight{
    color: var(--detail);
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-thumb {
    background: var(--fg);
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: var(--fg2);
}

ul{
    list-style: none;
}

ul li{
    position: relative;
    line-height: 1.6;
}

ul li p {
    display: flex;
    position: relative;
    align-items: center;
    margin: 0 5%;
}

span.blockspam {
    display: none;
  }

